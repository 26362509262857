const stories_class = '.stories';
    storiesButtons = document.querySelectorAll(`${stories_class} ${stories_class}__types--category`),
    storiesCards = document.querySelectorAll(`${stories_class} ${stories_class}__card`),
    stories_class_active = 'stories-active';

storiesButtons.forEach( (btn) => {
    btn.addEventListener('click', () => {
        const selectedStoryID = btn.getAttribute('data-story-id');
        storiesButtons.forEach((button) => {
            button.classList.remove(stories_class_active);
        });
        storiesCards.forEach((card) => {
            card.classList.remove(stories_class_active);
        });
        
        btn.classList.add(stories_class_active);
        storiesCards[selectedStoryID].classList.add(stories_class_active);        
    })
})